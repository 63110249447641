import { applyMiddleware, combineReducers, compose } from "redux";
import { createStore } from "redux";
import thunk from "redux-thunk";
import { QuanLyPhimReducer } from "./reducers/quanLyPhimReducer";
import { QuanLyRapReducer } from "./reducers/quanLyRapReducer";
import { QuanLyDatVeReducer } from "./reducers/quanLyDatVeReducer";
import { QuanLyNguoiDungReducer } from "./reducers/quanLyNguoiDungReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  QuanLyPhimReducer,
  QuanLyRapReducer,
  QuanLyDatVeReducer,
  QuanLyNguoiDungReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
