import { quanLyNguoiDungService } from "../../services/quanLyNguoiDungService";
import { TOKEN, USER_LOGIN } from "../../utils/settings/config";
import * as QuanLyNguoiDungType from "../types/quanLyNguoiDungType";

export const dangNhapAction = (thongTinDangNhap, history) => {
  return async (dispatch) => {
    try {
      const result = await quanLyNguoiDungService.dangNhap(thongTinDangNhap);

      dispatch({
        type: QuanLyNguoiDungType.DANG_NHAP,
        payload: result,
      });

      history.goBack();
    } catch (error) {
      dispatch({
        type: QuanLyNguoiDungType.DANG_NHAP,
        payload: error.response,
      });
    }
  };
};

export const dangKyAction = (thongTinDangKy, history) => {
  return async (dispatch) => {
    try {
      await quanLyNguoiDungService.dangKy(thongTinDangKy);

      const result = await quanLyNguoiDungService.dangNhap(thongTinDangKy);

      dispatch({
        type: QuanLyNguoiDungType.DANG_NHAP,
        payload: result,
      });

      history.goBack();
    } catch (error) {
      dispatch({
        type: QuanLyNguoiDungType.DANG_KY,
        payload: error.response,
      });
    }
  };
};

export const dangXuatAction = (history) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem(USER_LOGIN);
      localStorage.removeItem(TOKEN);

      await dispatch({
        type: QuanLyNguoiDungType.DANG_XUAT,
      });

      history.replace("/");
    } catch (error) {
      console.log([error]);
    }
  };
};

export const layThongTinNguoiDungAction = (taiKhoan) => {
  return async (dispatch) => {
    try {
      const result = await quanLyNguoiDungService.thongTinTaiKhoan(taiKhoan);

      dispatch({
        type: QuanLyNguoiDungType.LAY_THONG_TIN_NGUOI_DUNG,
        payload: result.data,
      });
    } catch (error) {
      console.log([error]);
    }
  };
};

export const capNhatThongTinNguoiDungAction = (thongTinCapNhat) => {
  return async (dispatch) => {
    try {
      const result = await quanLyNguoiDungService.capNhatThongTinNguoiDung(
        thongTinCapNhat
      );
      await dispatch({
        type: QuanLyNguoiDungType.CAP_NHAT_THONG_TIN_NGUOI_DUNG,
        payload: result,
      });
    } catch (error) {
      await dispatch({
        type: QuanLyNguoiDungType.CAP_NHAT_THONG_TIN_NGUOI_DUNG,
        payload: error.response,
      });
    }
  };
};
