export const LAY_DANH_SACH_PHIM = "@quanLyPhimReducer/LAY_DANH_SACH_PHIM";
export const LAY_DANH_SACH_PHIM_BANNER =
  "@quanLyPhimReducer/LAY_DANH_SACH_PHIM_BANNER";
export const CAP_NHAT_THONG_TIN_PHIM_BANNER =
  "@quanLyPhimReducer/CAP_NHAT_THONG_TIN_PHIM_BANNER";
export const LAY_DANH_SACH_PHIM_DANG_CHIEU =
  "@quanLyPhimReducer/LAY_DANH_SACH_PHIM_DANG_CHIEU";
export const LAY_DANH_SACH_PHIM_SAP_CHIEU =
  "@quanLyPhimReducer/LAY_DANH_SACH_PHIM_SAP_CHIEU";
export const LAY_THONG_TIN_PHIM = "@quanLyPhimReducer/LAY_THONG_TIN_PHIM";
