import React from "react";
import { ArrowUpIcon } from "@heroicons/react/outline";

export default function BackToTop() {
  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div
      id="backToTopBtn"
      className="opacity-0 bg-white rounded-full shadow-md w-12 h-12 fixed bottom-10 right-10 z-50 transition-all duration-300"
    >
      <button onClick={backToTop} className="w-full h-full rounded-full">
        <ArrowUpIcon className="p-3 text-gray-500 hover:text-black transition-all duration-200" />
      </button>
    </div>
  );
}
