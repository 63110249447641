import React from "react";
import "./loading.css";

export default function Loading() {
  return (
    <div className="h-screen bg-white flex justify-center">
      <div className="loader"></div>
    </div>
  );
}
