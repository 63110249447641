import { Fragment, useEffect } from "react";
import { Route } from "react-router";
import BackToTop from "../../components/BackToTop/backToTop";
import Footer from "./Layout/Footer/footer";
import Header from "./Layout/Header/header";

export const HomeTemplate = (props) => {
  const { Component, ...restProps } = props;

  useEffect(() => {
    window.scrollTo(0, 0);

    const backToTopBtn = document.querySelector("#backToTopBtn");

    window.onscroll = () => {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.querySelectorAll("#header #header__item a").forEach((item) => {
          item.style.color = "rgba(107, 114, 128)";
        });
      } else {
        if (document.getElementById("header")) {
          document.getElementById("header").style.backgroundColor =
            "transparent";
        }

        document
          .querySelectorAll("#header #header__item a")
          ?.forEach((item) => {
            item.style.color = "#fff";
          });
      }

      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        backToTopBtn.style.opacity = "1";
      } else {
        backToTopBtn.style.opacity = "0";
      }
    };
  }, []);

  return (
    <Route
      {...restProps}
      render={(propsRoute) => {
        return (
          <Fragment>
            <Header />
            <Component {...propsRoute} />
            <BackToTop />
            <Footer />
          </Fragment>
        );
      }}
    />
  );
};
