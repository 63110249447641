import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  MenuIcon,
  PhoneIcon,
  XIcon,
  HomeIcon,
  NewspaperIcon,
  GiftIcon,
} from "@heroicons/react/outline";
import { BellIcon, UserIcon, CogIcon, ClockIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import "./header.css";
import { TOKEN, USER_LOGIN } from "../../../../utils/settings/config";
import { Menu, Dropdown } from "antd";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { dangXuatAction } from "../../../../redux/actions/quanLyNguoiDungAction";
// import { useDispatch } from "react-redux";
// import { layThongTinNguoiDungAction } from "../../../../redux/actions/quanLyNguoiDungAction";

const items = [
  {
    name: "Trang chủ",
    description: "Home",
    href: "#a",
    icon: HomeIcon,
  },
  {
    name: "Tin tức",
    description: "News",
    href: "#a",
    icon: NewspaperIcon,
  },
  {
    name: "Khuyến mãi",
    description: "KM",
    href: "#a",
    icon: GiftIcon,
  },
  {
    name: "Liên hệ",
    description: "Contact",
    href: "#a",
    icon: PhoneIcon,
  },
];

const menuUser = (hoTen, email, history, dispatch) => {
  const dangXuat = () => {
    dispatch(dangXuatAction(history));
  };

  return (
    <Menu>
      <Menu.Item
        key="0"
        style={{
          padding: "8px 80px 8px 16px",
          backgroundColor: "white",
          userSelect: "none",
          cursor: "default",
          width: "20%",
        }}
      >
        <div className="">
          <h3 className="text-lg font-bold select-none">{hoTen}</h3>
          <p className="mb-0 text-sm text-gray-500 select-none">{email}</p>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        icon={<UserIcon className="w-4 h-4" />}
        style={{ padding: "10px 16px" }}
      >
        <NavLink to="/profile" className="">
          Hồ sơ
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="2"
        icon={<CogIcon className="w-4 h-4" />}
        style={{ padding: "6px 16px 10px" }}
      >
        <NavLink to="/profile-setting" className="">
          Cài đặt
        </NavLink>
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{
          marginTop: "8px",
          padding: "0px 16px 12px",
          backgroundColor: "white",
        }}
      >
        <button
          className="w-full mx-auto whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border-2 border-gray-300 rounded text-sm font-semibold text-black hover:bg-gray-300 hover:text-white transition-all duration-200"
          onClick={dangXuat}
        >
          Đăng xuất
        </button>
      </Menu.Item>
    </Menu>
  );
};

const menuNoti = () => {
  return (
    <Menu>
      <Menu.Item
        key="0"
        style={{
          padding: "8px 16px",
          backgroundColor: "white",
          userSelect: "none",
          cursor: "default",
        }}
      >
        <div className="">
          <h3 className="text-lg font-bold select-none">Thông báo</h3>
          <p className="mb-0 text-sm text-gray-500 select-none">
            Bạn có 1 thông báo chưa đọc
          </p>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.ItemGroup title="Thông báo mới" style={{ padding: "2px 4px" }}>
        <Menu.Item key="1" className="bg-gray-200 rounded">
          <div className="flex flex-row justify-start items-center">
            <div
              className="w-9 h-9 bg-center bg-no-repeat bg-cover mr-4 rounded-full"
              style={{ backgroundImage: `url(https://i.pravatar.cc/300)` }}
            ></div>
            <div>
              <p className="mb-0 text-sm">Bạn đã đặt vé cho phim Black Widow</p>
              <div className="flex flex-row justify-start items-center mt-2 text-gray-500">
                <ClockIcon className="w-4 h-4 mr-1" />
                <p className="mb-0 text-xs">21 phút trước</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Thông báo đã đọc" style={{ padding: "2px 4px" }}>
        <Menu.Item key="2">
          <div className="flex flex-row justify-start items-center">
            <div
              className="w-9 h-9 bg-center bg-no-repeat bg-cover mr-4 rounded-full"
              style={{ backgroundImage: `url(https://i.pravatar.cc/300)` }}
            ></div>
            <div>
              <p className="mb-0 text-sm">Bạn đã đặt vé cho phim Conan</p>
              <div className="flex flex-row justify-start items-center mt-2 text-gray-500">
                <ClockIcon className="w-4 h-4 mr-1" />
                <p className="mb-0 text-xs">55 phút trước</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="3" style={{ padding: "6px 16px" }}>
        <p className="mb-0 text-center text-base text-blue-600 font-bold">
          Xem tất cả
        </p>
      </Menu.Item>
    </Menu>
  );
};

function Header(props) {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(layThongTinNguoiDungAction());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { history } = props;

  const renderHeaderRight = () => {
    if (localStorage.getItem(TOKEN)) {
      if (localStorage.getItem(USER_LOGIN)) {
        const userLogin = localStorage.getItem(USER_LOGIN);
        const { hoTen, email } = JSON.parse(userLogin);
        return (
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Dropdown
              overlay={menuNoti}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="rounded overflow-hidden"
            >
              <div className="relative w-9 h-9 p-2 mr-6 cursor-pointer rounded-full hover:bg-gray-700 transition-all duration-150 transform hover:scale-100">
                <div className="absolute top-0 right-0 w-4 h-4 bg-blue-600 border rounded-full text-gray-800 border-gray-50 flex justify-center items-center">
                  <p className="mb-0 text-xss leading-1 text-white">1</p>
                </div>
                <BellIcon />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menuUser(hoTen, email, history, dispatch)}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="rounded overflow-hidden shadow-md"
            >
              <div
                className="ant-dropdown-link w-9 h-9 rounded-full overflow-hidden border border-gray-300 bg-center bg-cover bg-no-repeat cursor-pointer transition-all duration-100 transform hover:scale-105"
                style={{
                  backgroundImage: `url(https://i.pravatar.cc/300?u=${email})`,
                }}
              ></div>
            </Dropdown>
          </div>
        );
      }
    }

    return (
      <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
        <NavLink
          to="/login"
          className="whitespace-nowrap text-sm font-semibold text-gray-500 hover:text-gray-900"
        >
          Đăng nhập
        </NavLink>
        <NavLink
          to="/register"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow-md text-sm font-semibold text-white bg-blue-600 hover:bg-blue-700 hover:text-white"
        >
          Đăng ký
        </NavLink>
      </div>
    );
  };

  return (
    <Popover
      className="fixed h-14 w-full shadow-md z-50 bg-transparent transition-all duration-200 text-white backdrop-filter backdrop-blur"
      id="header"
    >
      <div className="container mx-auto">
        <div className="flex justify-between items-center py-2 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/">
              <span className="sr-only">Workflow</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="image/logo.png"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../image/logo.png";
                }}
                alt="..."
              />
            </NavLink>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden md:flex space-x-10"
            id="header__item"
          >
            <NavLink
              to="/home"
              className="text-base font-bold text-white hover:text-gray-900"
              activeClassName="nav_acive"
            >
              Trang chủ
            </NavLink>
            <NavLink
              to="/news"
              className="text-base font-bold text-white hover:text-gray-900"
              activeClassName="nav_acive"
            >
              Tin tức
            </NavLink>
            <NavLink
              to="/promotion"
              className="text-base font-bold text-white hover:text-gray-900"
              activeClassName="nav_acive"
            >
              Khuyến mãi
            </NavLink>
            <NavLink
              to="/contact"
              className="text-base font-bold text-white hover:text-gray-900"
              activeClassName="nav_acive"
            >
              Liên hệ
            </NavLink>
          </Popover.Group>
          {renderHeaderRight()}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {items.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-sm font-medium text-gray-900">
                        {item.name}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <a
                  href="#a"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Đăng ký
                </a>
                <p className="mt-6 text-center text-sm font-medium text-gray-500">
                  Đã có tài khoản?{" "}
                  <a
                    href="#a"
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Đăng nhập
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default withRouter(Header);
