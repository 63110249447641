import * as QuanLyPhimType from "../types/quanLyPhimType";

const initState = {
  danhSachPhim: [
    {
      maPhim: 1358,
      tenPhim: "Inside Out",
      biDanh: "inside-out",
      trailer: "https://www.youtube.com/embed/seMwpP0yeu4",
      hinhAnh: "http://movieapi.cyberlearn.vn/hinhanh/insideout.jpg",
      moTa: "After young Riley is uprooted from her Midwest life and moved to San Francisco, her emotions - Joy, Fear, Anger, Disgust and Sadness - conflict on how best to navigate a new city, house, and school.",
      maNhom: "GP15",
      ngayKhoiChieu: "2019-07-29T00:00:00",
      danhGia: 5,
      hot: true,
      dangChieu: false,
      sapChieu: true,
    },
  ],
  danhSachPhimDangChieu: [],
  danhSachPhimSapChieu: [],
  danhSachPhimBanner: [],
  phimDangChon: {},
};

export const QuanLyPhimReducer = (state = initState, action) => {
  switch (action.type) {
    case QuanLyPhimType.LAY_DANH_SACH_PHIM: {
      state.danhSachPhim = action.payload;
      return { ...state };
    }

    case QuanLyPhimType.LAY_DANH_SACH_PHIM_BANNER:
      state.danhSachPhimBanner = action.payload;
      return { ...state };

    case QuanLyPhimType.CAP_NHAT_THONG_TIN_PHIM_BANNER:
      let danhSachPhimBanner = state.danhSachPhimBanner;
      state.danhSachPhimBanner = danhSachPhimBanner.map((item) => {
        if (item.maPhim === action.payload.maPhim) {
          let { tenPhim, moTa, trailer } = action.payload;
          let tenPhimReplace = [
            ["Ban tay diet quy", "Bàn tay diệt quỷ"],
            ["Lat mat 48h", "Lật mặt 48h"],
            ["Cuộc Chiến Sinh Tử Abc", "Cuộc chiến sinh tử"],
          ];
          tenPhimReplace.forEach((item) => {
            tenPhim = tenPhim.replace(item[0], item[1]);
          });

          return { ...item, tenPhim, moTa, trailer };
        } else {
          return { ...item };
        }
      });
      return { ...state };

    case QuanLyPhimType.LAY_DANH_SACH_PHIM_DANG_CHIEU:
      // state.danhSachPhimDangChieu = state.danhSachPhim.filter(
      //   (phim) => phim.dangChieu
      // );
      state.danhSachPhimDangChieu = state.danhSachPhim;
      return { ...state };

    case QuanLyPhimType.LAY_DANH_SACH_PHIM_SAP_CHIEU:
      // state.danhSachPhimSapChieu = state.danhSachPhim.filter(
      //   (phim) => phim.sapChieu
      // );
      state.danhSachPhimSapChieu = state.danhSachPhim;
      return { ...state };

    case QuanLyPhimType.LAY_THONG_TIN_PHIM:
      state.phimDangChon = action.payload;
      return { ...state };

    default:
      return { ...state };
  }
};
