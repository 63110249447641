import React, { lazy } from "react";
import { HomeTemplate } from "./../templates/HomeTemplate/homeTemplate";
import { AdminTemplate } from "./../templates/AdminTemplate/adminTemplate";
import { AuthTemplate } from "./../templates/AuthTemplate/authTemplate";

const routesHome = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("./../pages/Home/home")),
  },
  {
    path: "/home",
    exact: false,
    component: lazy(() => import("./../pages/Home/home")),
  },
  {
    path: "/news",
    exact: false,
    component: lazy(() => import("./../pages/News/news")),
  },
  {
    path: "/promotion",
    exact: false,
    component: lazy(() => import("./../pages/Promotion/promotion")),
  },
  {
    path: "/contact",
    exact: false,
    component: lazy(() => import("./../pages/Contact/contact")),
  },
  {
    path: "/detail/:id",
    exact: false,
    component: lazy(() => import("./../pages/Detail/detail")),
  },
  {
    path: "/checkout/:id",
    exact: false,
    component: lazy(() => import("./../pages/Checkout/checkout")),
  },
  {
    path: "/profile",
    exact: false,
    component: lazy(() => import("./../pages/Profile/UserProfile/userProfile")),
  },
  {
    path: "/profile-setting",
    exact: false,
    component: lazy(() => import("./../pages/Profile/UserSetting/userSetting")),
  },
];

const routesAdmin = [
  {
    path: "/admin",
    exact: false,
    component: lazy(() => import("./../pages/Admin/admin")),
  },
];

const routesAuth = [
  {
    path: "/login",
    exact: false,
    component: lazy(() => import("./../pages/Auth/auth")),
  },
  {
    path: "/register",
    exact: false,
    component: lazy(() => import("./../pages/Auth/auth")),
  },
];

export function RoutesHome() {
  return routesHome.map((route, index) => {
    return (
      <HomeTemplate
        key={index}
        path={route.path}
        exact={route.exact}
        Component={route.component}
      />
    );
  });
}

export function RoutesAdmin() {
  return routesAdmin.map((route, index) => {
    return (
      <AdminTemplate
        key={index}
        path={route.path}
        exact={route.exact}
        Component={route.component}
      />
    );
  });
}

export function RoutesAuth() {
  return routesAuth.map((route, index) => {
    const { Component, ...restProps } = route;
    return <AuthTemplate key={index} {...restProps} Component={Component} />;
  });
}
