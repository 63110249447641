import * as QuanLyRapType from "../types/quanLyRapType";
import { quanLyRapService } from "./../../services/quanLyRapService";

export const layThongTinHeThongRapAction = () => {
  return async (dispatch) => {
    try {
      const result = await quanLyRapService.layThongTinHeThongRap();

      dispatch({
        type: QuanLyRapType.LAY_THONG_TIN_HE_THONG_RAP,
        payload: result.data.content,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const layThongTinCumRapTheoHeThongAction = (maHeThongRap) => {
  return async (dispatch) => {
    try {
      const result = await quanLyRapService.layThongTinCumRapTheoHeThong(
        maHeThongRap
      );

      dispatch({
        type: QuanLyRapType.LAY_THONG_TIN_CUM_RAP_THEO_HE_THONG,
        payload: result.data.content,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const layThongTinLichChieuHeThongRapAction = (maHeThongRap = "") => {
  return async (dispatch) => {
    try {
      const result = await quanLyRapService.layThongTinLichChieuHeThongRap(
        maHeThongRap
      );

      dispatch({
        type: QuanLyRapType.LAY_THONG_TIN_LICH_CHIEU_HE_THONG_RAP,
        payload: result.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const layTHongTinLichChieuPhimAction = (maPhim = "") => {
  return async (dispatch) => {
    try {
      const result = await quanLyRapService.layThongTinLichChieuPhim(maPhim);

      dispatch({
        type: QuanLyRapType.LAY_THONG_TIN_LICH_CHIEU_PHIM,
        payload: result.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
