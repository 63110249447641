import api from "./baseService";
import { GROUP_ID } from "../utils/settings/config";

class QuanLyNguoiDungService {
  layDanhSachLoaiNguoiDung = () => {
    return api.get("/api/QuanLyNguoiDung/LayDanhSachLoaiNguoiDung");
  };

  dangNhap = (thongTinDangNhap) => {
    return api.post("/api/QuanLyNguoiDung/DangNhap", thongTinDangNhap);
  };

  dangKy = (thongTinDangKy) => {
    return api.post("/api/QuanLyNguoiDung/DangKy", thongTinDangKy);
  };

  layDanhSachNguoiDung = () => {
    return api.get(
      `/api/QuanLyNguoiDung/LayDanhSachNguoiDung?MaNhom=${GROUP_ID}`
    );
  };

  layDanhSachNguoiDungPhanTrang = (soTrang, soPhanTuTrenTrang) => {
    return api.get(
      `/api/QuanLyNguoiDung/LayDanhSachNguoiDungPhanTrang?MaNhom=${GROUP_ID}&soTrang=${soTrang}&soPhanTuTrenTrang=${soPhanTuTrenTrang}`
    );
  };

  timKiemNguoiDung = () => {
    return api.get(`/api/QuanLyNguoiDung/TimKiemNguoiDung?MaNhom=${GROUP_ID}`);
  };

  timKiemNguoiDungPhanTrang = (soTrang, soPhanTuTrenTrang) => {
    return api.get(
      `/api/QuanLyNguoiDung/TimKiemNguoiDungPhanTrang?MaNhom=${GROUP_ID}&soTrang=${soTrang}&soPhanTuTrenTrang=${soPhanTuTrenTrang}`
    );
  };

  thongTinTaiKhoan = (taiKhoan) => {
    console.log(taiKhoan);
    return api.post("/api/QuanLyNguoiDung/ThongTinTaiKhoan", taiKhoan);
  };

  themNguoiDung = (thongTinNguoiDung) => {
    return api.post("/api/QuanLyNguoiDung/ThemNguoiDung", thongTinNguoiDung);
  };

  capNhatThongTinNguoiDung = (thongTinNguoiDung) => {
    return api.put(
      "/api/QuanLyNguoiDung/CapNhatThongTinNguoiDung",
      thongTinNguoiDung
    );
  };

  xoaNguoiDung = (tenTaiKhoan) => {
    return api.delete("/api/QuanLyNguoiDung/XoaNguoiDung", tenTaiKhoan);
  };
}

export const quanLyNguoiDungService = new QuanLyNguoiDungService();
