import { Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "./App.css";
import { RoutesAdmin, RoutesHome, RoutesAuth } from "./routes/routes";
import PageNotFound from "./pages/PageNotFound/pageNotFound";
import Loading from "./components/Loading/loading";
// import { createBrowserHistory } from "history";

// export const history = createBrowserHistory();

function App() {
  return (
    <Suspense fallback={<Loading />}>
      {/* <Router history={history}> */}
      <Switch>
        {RoutesHome()}
        {RoutesAdmin()}
        {RoutesAuth()}
        <Route path="" component={PageNotFound} />
      </Switch>
      {/* </Router> */}
    </Suspense>
  );
}

export default withRouter(App);
// export default App;
