import * as QuanLyNguoiDungType from "../types/quanLyNguoiDungType";
import { TOKEN, USER_LOGIN } from "../../utils/settings/config";

let user = {};
if (localStorage.getItem(USER_LOGIN)) {
  user = JSON.parse(localStorage.getItem(USER_LOGIN));
}

const initState = {
  userLogin: user,
  userInfo: user,
  error: "",
  updateUserInfoError: "",
};

export const QuanLyNguoiDungReducer = (state = initState, action) => {
  switch (action.type) {
    case QuanLyNguoiDungType.DANG_NHAP: {
      if (action.payload.status === 200) {
        localStorage.setItem(USER_LOGIN, JSON.stringify(action.payload.data));

        state.userLogin = action.payload.data;

        localStorage.setItem(TOKEN, action.payload.data.accessToken);
        state.error = "";
      } else {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(USER_LOGIN);
        state.error = action.payload.data;
      }
      return { ...state };
    }
    // case QuanLyNguoiDungType.DANG_KY: {
    //   localStorage.removeItem(TOKEN);
    //   localStorage.removeItem(USER_LOGIN);

    //   if (action.payload.statusCode === 200) {
    //     localStorage.setItem(
    //       USER_LOGIN,
    //       JSON.stringify(action.payload.content)
    //     );

    //     state.userLogin = action.payload.content;

    //     localStorage.setItem(TOKEN, action.payload.content.accessToken);
    //     state.error = "";
    //   } else {
    //     console.log(action.payload.content);
    //     state.error = action.payload.content;
    //   }

    //   return { ...state };
    // }

    case QuanLyNguoiDungType.LAY_THONG_TIN_NGUOI_DUNG: {
      state.userInfo = action.payload;
      return { ...state };
    }

    case QuanLyNguoiDungType.CAP_NHAT_THONG_TIN_NGUOI_DUNG: {
      if (action.payload.status === 200) {
        state.userLogin = { ...state.userLogin, ...action.payload.data };
        localStorage.setItem(USER_LOGIN, JSON.stringify(state.userLogin));
        state.updateUserInfoError = "";
      } else {
        state.updateUserInfoError = action.payload.data;
      }

      return { ...state };
    }

    case QuanLyNguoiDungType.DANG_XUAT: {
      state.userLogin = {};
      state.userInfo = {};

      return { ...state };
    }

    default:
      return { ...state };
  }
};
