import api from "./baseService";
import { GROUP_ID } from "../utils/settings/config";

class QuanLyRapService {
  layThongTinHeThongRap = (maHeThongRap) => {
    return api.get(
      `/api/QuanLyRap/LayThongTinHeThongRap?maHeThongRap=${maHeThongRap}`
    );
  };

  layThongTinCumRapTheoHeThong = (maHeThongRap) => {
    return api.get(
      `/api/QuanLyRap/LayThongTinCumRapTheoHeThong?maHeThongRap=${maHeThongRap}`
    );
  };

  layThongTinLichChieuHeThongRap = (maHeThongRap) => {
    return api.get(
      `/api/QuanLyRap/LayThongTinLichChieuHeThongRap?maHeThongRap=${maHeThongRap}&maNhom=${GROUP_ID}`
    );
  };

  layThongTinLichChieuPhim = (maPhim) => {
    return api.get(`/api/QuanLyRap/LayThongTinLichChieuPhim?MaPhim=${maPhim}`);
  };
}

export const quanLyRapService = new QuanLyRapService();
