import * as QuanLyDatVeType from "../types/quanLyDatVeType";

const initState = {
  thongTinPhim: {},
  danhSachGhe: [],
  danhSachGheDangDat: [],
};

export const QuanLyDatVeReducer = (state = initState, action) => {
  switch (action.type) {
    case QuanLyDatVeType.LAY_DANH_SACH_PHONG_VE: {
      state.thongTinPhim = action.payload.thongTinPhim;
      state.danhSachGhe = action.payload.danhSachGhe;
      return { ...state };
    }

    case QuanLyDatVeType.DAT_GHE: {
      let danhSachGheDangDatNew = [...state.danhSachGheDangDat];
      let seatIndex = danhSachGheDangDatNew.findIndex(
        (item) => item.maGhe === action.payload.maGhe
      );
      if (seatIndex === -1) {
        danhSachGheDangDatNew.push(action.payload);
      } else {
        danhSachGheDangDatNew.splice(seatIndex, 1);
      }

      state.danhSachGheDangDat = danhSachGheDangDatNew;

      return { ...state };
    }

    case QuanLyDatVeType.DAT_VE_HOAN_TAT: {
      state.danhSachGheDangDat = [];
      return { ...state };
    }

    default:
      return { ...state };
  }
};
