import * as QuanLyRapType from "../types/quanLyRapType";

const initState = {
  heThongRap: [
    // {
    //   lstCumRap: [],
    //   maHeThongRap: "BHDStar",
    //   tenHeThongRap: "BHD Star Cineplex",
    //   logo: "http://movieapi.cyberlearn.vn/hinhanh/bhd-star-cineplex.png",
    // },
  ],
  lichChieuPhim: [],
};

export const QuanLyRapReducer = (state = initState, action) => {
  switch (action.type) {
    case QuanLyRapType.LAY_THONG_TIN_HE_THONG_RAP: {
      return { ...state };
    }

    case QuanLyRapType.LAY_THONG_TIN_CUM_RAP_THEO_HE_THONG: {
      return { ...state };
    }

    case QuanLyRapType.LAY_THONG_TIN_LICH_CHIEU_HE_THONG_RAP: {
      state.heThongRap = action.payload;
      return { ...state };
    }

    case QuanLyRapType.LAY_THONG_TIN_LICH_CHIEU_PHIM: {
      state.lichChieuPhim = action.payload.heThongRapChieu;
      return { ...state };
    }

    default:
      return { ...state };
  }
};
